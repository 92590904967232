<template>
    <div class="header_box">
        <div class="header_left">
            <span class="back" @click="goBack()">返回</span>
            <div class="time_box">
<!--                <span class="year">2020-10-13&nbsp;20:48:32&nbsp;星期二</span>-->
                <span class="year">{{nowtime}}</span>
                <!--                    <span class="time">20:48:32</span>-->
                <!--                    <span class="day">星期二</span>-->
            </div>
        </div>
        <div class="header_right">
                <span class="window">
                    <ul class="window_list">
                        <li class="window_item" @click="fullScreen()"><img class="img" src="@/assets/images/all@2x.png" />全屏</li>
                        <li class="window_item" @click="exit"><img class="img" src="@/assets/images/out@2x.png" />退出</li>
                    </ul>
                </span>
            <div class="user_box">
                <span><img class="img" src="@/assets/images/admin@2x.png" />欢迎您</span>
                <span class="line">,</span>
                <span>{{userName}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {logout} from "@/api/login";
import moment from 'moment'

export default {
  name: "headerDataV",
  data(){
    return {
      userName:'',
      nowtime:'',
      timer:null,
    }
  },
  created() {
    this.userName = window.localStorage.getItem("userName");
  },
  mounted() {
    moment.locale('zh-cn')
    let _this=this;
    this.timer = setInterval(()=>{
      _this.nowtime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss dddd')
    })
  },
  beforeDestroy() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  methods:{
    // 全屏
    fullScreen() {
      document.webkitExitFullscreen();
      document.documentElement.requestFullscreen();
    },
    // 退出
    async exit() {
      try {
        await this.$confirm("是否退出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        });
        const id = localStorage.getItem("id");
        logout({ id: id }).then(res => {
          if (res.code == 200) {
            localStorage.clear();
            localStorage.setItem("remember", false);
            window.location.href = "/";
            // this.$router.push({ path: "/" });
            this.$message.success("退出成功");
            // localStorage.clear();
            // localStorage.setItem("remember", false);
          } else {
            this.$message.error("服务器异常");
          }
        });
      } catch {}
    },
    goBack(){
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
