<template>
    <div class="title_warp">
        <div class="title">
            <span v-if="titleNum && titleNum.length != 0" class="titleNum">
                <!-- <span class="warning">{{titleNum[0]}}</span>/{{titleNum[1]}} -->
                </span>
            <div class="title_box">
                <span class="span">
                    <slot name="title"></slot>
                </span>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>

export default {
  name: 'titleSpan',
  props: {
    titleNum: {
      type: Array,
      default () {
        return []
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.title_warp{
    .title{
        position: relative;
        height: 40px;
        .titleNum{
            position: absolute;
            right: 28px;
            top: 56%;
            transform: translateY(-50%);
            font-size: 26px;
            font-weight: bold;
            color: #FFFFFF;
            z-index: 1;
            .warning{
                font-size: 26px;
                color: #F54932;
            }
        }
        .title_box{
            text-align: center;
            height: 30px;
            line-height: 30px;
            margin: 15px 0px;
            .span{
                .span_title{
                    position: relative;
                    letter-spacing:1px;
                    margin: 0px -2px;
                    display: block;
                    font-size: 22px;
                    font-weight: bold;
                    color: #28E0EE;
                    /*border-bottom:4px solid ;*/
                    /*border-image:linear-gradient(93deg, #2AEDF7 0%, #688CF3 100%) 4 10 4;*/
                    /*border-radius: 1px;;*/
                    padding-bottom: 10px;
                    .line{
                        position: absolute;
                        width: 25%;
                        height: 4px;
                        background: linear-gradient(93deg, #2AEDF7 0%, #688CF3 100%);
                        left: 50%;
                        bottom: 2px;
                        transform: translateX(-50%);
                    }
                    &:before{
                        content: ' ';
                        width: 175px;
                        height: 17px;
                        line-height: 17px;
                        position: absolute;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 20px;
                        background-image: url("~@/assets/images/span-left.png");
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                    }
                    &:after{
                        content: ' ';
                        width: 175px;
                        height: 17px;
                        line-height: 17px;
                        position: absolute;
                        display: block;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 20px;
                        background-image: url("~@/assets/images/span-right.png");
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        z-index: 0;
                    }
                }
            }

        }
    }
}
</style>
