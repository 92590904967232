<template>
    <div class="waterData">
        <div class="waterData_warp">
            <ul class="waterData_list">
                <li class="waterData_item">
                    <img class="img" src="@/assets/images/dataV1.png" alt="">
                    <div class="info">
                        <span class="info_title">管辖区域(km²)</span>
                        <span class="info_data">172</span>
                    </div>
                </li>
                <li class="waterData_item" @click="handleProce(1)" style="cursor: pointer;">
                    <img class="img" src="@/assets/images/dataV3.png" alt="">
                    <div class="info">
                        <span class="info_title">泵站数量(个)</span>
                        <span class="info_data">{{gateCount}}</span>
                    </div>
                </li>
                <li class="waterData_item" @click="handleProce(2)" style="cursor: pointer;">
                    <img class="img" src="@/assets/images/dataV4.png" alt="">
                    <div class="info">
                        <span class="info_title">拦河闸数量(个)</span>
                        <span class="info_data">{{siteCount}}</span>
                    </div>
                </li>
                <li class="waterData_item" @click="handleProce(3)" style="cursor: pointer;">
                    <img class="img" src="@/assets/images/dataV2.png" alt="">
                    <div class="info">
                        <span class="info_title">测流点数量(个)</span>
                        <span class="info_data">{{videoCount}}</span>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 弹框 -->
        <el-dialog
                class="border-r-8 role-dialog"
                :visible.sync="addShow"
                @closed="closeBox"
                :destroy-on-close="true"
                width="calc(388/1920*100vw)"
        >
            <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
            <div class="contentAdd" v-for="(i,index) in alarmInfo" :key="index">
              <div class="Police-icon t-x font-w-b">
               {{index+1}}
              </div>
              <div class="Police-text t-x font-w-b">
               {{i.staName||i.siteName||i.meterName||i.name}}
              </div>
              <el-button class="Police-btn" type="text" @click="handleDetail(i,title)">
              查看详情
            </el-button>
            </div>
            <span slot="footer" class="dialog-footer">
      </span>
        </el-dialog>
    </div>
</template>

<script>
import { selectCountList } from "@/api/index";
import axios from "../../../../common/axiosHttp";
export default {
  name: 'waterData',
  data(){
    return{
      videoCount: "", //测流点数量
      siteCount: "", //拦河闸数量
      gateCount: "", //泵站数量
      addShow: false,
      title:"",
      alarmInfo: [],
    }
  },
  created() {
    this.getData()
  //  this.show()
  },
  mounted() {
  },
  methods:{
    getData(){
      selectCountList().then(res => {
        this.videoCount = res.data.flowMeter; //测流点数量
        this.siteCount = res.data.siteCount; //拦河闸数量
        this.gateCount = res.data.pumpStationCount; //泵站数量
      });
    },
    handleProce (val) {
      if(val==1){
        this.title = '泵站数量'
        this.show("./pumpStation/getPumpStations")
      }else if(val==2){
        this.title = '拦河闸数量'
        this.show("./site/findList")
      }else if(val==3){
        this.title = '测流点数量'
        this.show("./site/getAllSite",val)
      }
      this.addShow = true

    //  this.alarmInfo = item
      // this.alarmInfo.type = this.typeList[item.type - 1]
      // this.alarmInfo.userName = localStorage.getItem('userName')
    },
    handleDetail(val,name){
      // console.log(val);
      if(name=='泵站数量'){
        this.$router.push(`/main/pumpStation/${val.id}/1-1`)
      }else if(name=='拦河闸数量'){
         this.$router.push(`/main/StationManage/${val.id}`)
      }else if(name=='测流点数量'){
        this.$router.push( `/simpleness?lat=${val.lat}&lon=${val.lon}`)
      }
    },
     // 获取泵站列表
    show(val,num=0){
      if(num!=3){
        axios.get(val).then(res=>{
          // console.log(res.data);
          this.alarmInfo = res.data
        })
      }else{
        axios.get(val).then(res=>{

          let ulList=res.data.ditchFlowMeters.concat(res.data.flowMeters)
           // console.log(ulList);
          this.alarmInfo = ulList
        })
      }
    },
    closeBox () {
      this.$refs.ruleForm.resetFields()
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
::v-deep .el-dialog {
    background: rgba(6,108,151,0.6);
    border-radius: 16px;
    .el-dialog__header {
        background: rgba(6,108,151,0.6);
        border-radius: 16px 16px 0 0;
        color: #fff;
    }
    .el-dialog__footer {

        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(6,108,151,0.6);
        border-radius: 0 0 16px 16px;
        padding: calc(15 / 1080 * 100vh) 0;
        .el-button:nth-child(1):hover {
            background: #12B2D3;
            border: 1px #eaeaea solid;
            color: #fff;
        }
    }
    // 弹框主体
    .el-dialog__body {
        background: rgba(6,108,151,0.6);
        height: calc(395 / 1080 * 100vh);
        // 菜单选框
        .el-checkbox-group {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            label {
                width: 34% !important;
                height: calc(25 / 1080 * 100vh);
                margin-right: 0;

            }
        }
        // 滚动条
        &::-webkit-scrollbar {
        width: 10px;
        background-color: #ccc;

      }
      &::-webkit-scrollbar-thumb {
        background-color: #0ae;
         border-radius: 20px;
      }
      &::-webkit-scrollbar-track {

        background-color: #ccc;
      }

    }
    .el-dialog__headerbtn .el-dialog__close{
      color: #fff;
    }
}
.contentAdd{
  display: flex;
  padding: 10px 0px 15px 20px;
}
.Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    color: #fff;
    line-height:calc(23 / 1920 * 100vw);
  }
  .Police-text {
    text-align: left;
    width: calc(220 / 1920 * 100vw);
    color: #fff;
    font-weight: bold;
    margin-left: calc(20 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .dialogTitle{
    margin-bottom: 10px;
  }
  .Police-btn {
    color: #6BC162;
    font-weight: bold;
  }

</style>
