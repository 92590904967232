<template>
    <div class="bg_warp">
        <headerDataV></headerDataV>
        <div class="main">
            <div class="left">
                <div class="left_warp">
                    <titleSpan>
                        <span slot="title" class="span_title">
                            年度闸开启统计
                            <div class="line"></div>
                        </span>
                        <cakeShape />
                    </titleSpan>
                </div>
                <div class="left_warp">
                    <titleSpan>
                        <span slot="title" class="span_title">
                            年度水位曲线图
                            <div class="line"></div>
                        </span>
                        <lineChart />
                    </titleSpan>
                </div>
                <div class="left_warp">
                    <titleSpan>
                        <span slot="title" class="span_title">
                            年度流量柱状图
                            <div class="line"></div>
                        </span>
                        <columnar />
                    </titleSpan>
                </div>
            </div>
            <div class="center">
                <centerDisk />
                <div class="center_warp">
                    <titleSpan>
                        <span slot="title" class="span_title">
                            泵站实时信息
                            <div class="line"></div>
                        </span>
                        <swipers />
                    </titleSpan>
                </div>
            </div>
            <div class="right">
                <div class="right_warp">
                    <titleSpan>
                        <span slot="title" class="span_title">
                            水利数据信息
                            <div class="line"></div>
                        </span>
                        <waterData />
                    </titleSpan>
                </div>
                <div class="right_warp">
                    <titleSpan :titleNum="titleNum">
                        <span slot="title" class="span_title">
                            测流点实时信息
                            <div class="line"></div>
                        </span>
                        <realTime @titleNum="titleNumHandler"/>
                    </titleSpan>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headerDataV from './components/header/header'
import titleSpan from './components/title/title'
import cakeShape from './components/cakeShape/cakeShape'
import lineChart from './components/lineChart/lineChart'
import columnar from './components/columnar/columnar'
import waterData from './components/waterData/waterData'
import realTime from './components/realTime/realTime'
import centerDisk from './components/centerDisk/centerDisk'
import swipers from './components/Swipers/Swipers'

export default {
  name: 'dataV',
  data () {
    return {
      titleNum: []
    }
  },
  components: {
    headerDataV,
    titleSpan,
    cakeShape,
    lineChart,
    columnar,
    waterData,
    realTime,
    centerDisk,
    swipers
  },
  methods: {
    titleNumHandler (val) {
        // console.log(val);
      this.titleNum = val
    }
  }
}
</script>

<style lang="scss" scoped>
 @import "./dataV.scss";
 .left_warp{
     position: relative;
 }
</style>
