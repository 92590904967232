<template>
    <div class="columnarChart">
      <div class="TitlesR">
          <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange">
            <el-option
              v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.deviceAddress">
            </el-option>
          </el-select>
        </div>
        <div class="columnarChart" id="columnarChart"></div>
    </div>
</template>

<script>
import { YearsList,siteGetSiteAll,FlowList } from '../../../../api/index'

export default {
  name: 'columnar',
  data () {
    return {
      waterList:[],//下拉
      water:'',
      lastTrafficList: [], // 后台返回的去年流量表
      nowTrafficList: [] // 后台返回的今年流量表
    }
  },
  mounted () {
    siteGetSiteAll().then(res=>{
      this.waterList=res.data
      this.water=this.waterList[0].deviceAddress
       this.flow=this.waterList[0].deviceAddress
      this.getData(this.waterList[0].deviceAddress);
     // this.dataProcessings(this.waterList[0].deviceAddress)
    })
  },
  methods: {
    init (last, now, year) {
      const columnarChart = document.getElementById('columnarChart')
      const myChart = this.$echarts.init(columnarChart)
      const option = {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
            color: '#D1D1D1'
          }
        },
        xAxis: {
          name:"月",
           nameTextStyle:{
            color:"#fff",
            fontSize:10,
        },
          type: 'category',
          data: year,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#EDFEFF',
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#1D56C0',
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name:"单位/m³",
           nameTextStyle:{
            color:"#fff",
            fontSize:10,
        },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#1D56C0'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#EDFEFF',
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{a1} {b1} 月：{c1} m³ <br> {a0} {b0} 月：{c0} m³'
        },
        series: [
          {
            name: '今年',
            data: now,
            type: 'bar',
            stack: 'year',
            emphasis: {
              focus: 'series'
            },
            barWidth: '57%',
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    color: '#FFFFFF',
                    fontSize: 12,
                    fontWeight: 'bold'
                  }
                },
                color: '#8270fc'
              }
            }
          },
          {
            name: '去年',
            data: last,
            type: 'bar',
            stack: 'year',
            emphasis: {
              focus: 'series'
            },
            barWidth: '57%',
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  textStyle: {
                    color: '#FFFFFF',
                    fontSize: 12,
                    fontWeight: 'bold'
                  }
                },
                color: '#28E0EE'
              }
            }
          }
        ]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    getData (val) {
      FlowList(val).then(res => {
        // console.log(res)
        // 去年流量表
        this.lastTrafficList = res.data.lastTraffic
        // 今年流量表
        this.nowTrafficList = res.data.nowTraffic
        this.init(
          this.lastTrafficList.map(item => item.sumTraffic),
          this.nowTrafficList.map(item => item.sumTraffic),
          res.data.nowTraffic.map(item => item.month))
      })
    },
    waterChange(val){
      this.getData(val)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.TitlesR{
  position:absolute;
  top: 20px;
  right: 15px;
  z-index: 999;
}
</style>
