<template>
    <div class="centerDisk">
        <div class="centerDisk_warp">
            <div class="center_list">
                <div class="item-box">
                    同比去年上升:
                    <span class="big">{{percentage}}%</span>
                </div>
                <div class="item-box">
                    闸开启总次数:
                    <span class="big">{{count}}</span>
                </div>
                <div class="item-box">
                    未开启站点：
                    <span class="big">{{unOpenCount}}</span>
                </div>
            </div>
            <div class="center_title">
                阳信水利
            </div>
        </div>
    </div>
</template>

<script>
import { gateUpRecordList } from '@/api/index'
export default {
  name: 'centerDisk',
  data () {
    return {
      percentage: '', // 同比去年上升
      count: '', // 闸泵开启总次数
      unOpenCount: '' // 未开启
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      gateUpRecordList().then(res => {
        this.percentage = res.data.percentage.percentage // 同比去年上升
        this.count = res.data.count // 闸泵开启总次数
        this.unOpenCount = res.data.unOpenCount // 未开启
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
