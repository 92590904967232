<template>
    <div class="cakeShape">
        <div class="container" id="container"></div>
    </div>
</template>

<script>
import { gateUpRecordList } from '@/api/index'
export default {
  name: 'cakeShape',
  data () {
    return {
      gateUpRecordVosList: []
    }
  },
  mounted () {
    // console.log('饼状图加载成功')
    this.getData()
  },
  methods: {
    init (data) {
      // console.log(this.gateUpRecordVosList)
      const container = document.getElementById('container')
      const myChart = this.$echarts.init(container)
      const option = {
        // formatter: ['{a|站点1}     {b|123}'].join('\n'),
        formatter: function (val) {
          var index = 0
          data.forEach(function (v, i) {
            if (v.siteName == val) {
              index = i
            }
          })
          return ['{a|' + val + '}' + '{' + 'b|' + data[index].count + '}']
        },
        legend: {
          top: 'center',
          right: 'right',
          data: data.map(item => { return item.siteName }),
          itemGap: 15,
          itemWidth: 14,
          itemHeight: 14,
          textStyle: {
            color: '#ffffff',
            rich: {
              a: {
                fontSize: 13,
                width: 60,
                align: 'left'
              },
              b: {
                color: '#38DBFA',
                fontSize: 13,
                fontWeight: 700,
                padding: [0, 0, 0, 40],
                width: 20,
                align: 'center'
              }
            }
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
          position: ["0%", "0%"]
        },
        color: ["#77C76E", "#EB7F67", "#C9CAEA",'#EAC9EA','#EBCD67','#6EC7B9',' #a6E85C7'],
        series: [
          {
            type: 'pie',
            radius: '80%',
            center: ["30%", "50%"],
            label: { 　　　　　　　　　　//去除饼图的指示折线label
              normal: {
              show: false,
              position: 'inside',
              formatter:"{b}:{d}%",
              },
            },
            data: data.map(item => { return { name: item.siteName, value: item.count } }),
            insideLabel: {
              show: true
            },
            roseType: true
          },
          {
            type: 'pie',
            radius: '80%',
            data: data.map(item => { return { name: item.siteName, value: item.count } }),
            insideLabel: {
              show: true
            },
           //roseType: true,
            label: {
              normal: {
                show: false,
                position: 'inner',
                formatter: '{d}%'
              }
            },
            center: ["30%", "50%"],
            emphasis: {
              show: true
            }
          }
        ]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    getData () {
      gateUpRecordList().then(res => {
        this.gateUpRecordVosList = res.data.gateUpRecordVos
        this.init(res.data.gateUpRecordVos)
      })
      // console.log(this.gateUpRecordVosList);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
