<template>
    <div class="realTime">
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div class="realTime_warp" v-else @click="clickPropsDetail($event)">
            <vue-seamless-scroll :data="list" :class-option="classOption" style="height:333px;padding: 10px 10px 0px 10px;overflow: hidden">
                <ul class="realTime_list"  >
                    <li class="realTime_item" v-for="(item , index) in list" :key="index">
                        <div class="Police-icon t-x font-w-b">
                        {{index+1}}
                      </div>
                        <span class="title_waring">{{item.meterName ||item.name}}</span>
                        <span class="btn_waring"  @click="handleProce(item)" :data-obj="JSON.stringify(item)"
                  :id="i + 1">查看详情</span>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
        <!-- 弹框 -->
        <el-dialog
                class="border-r-8 role-dialog"
                :visible.sync="addShow"
                @closed="closeBox"
                :destroy-on-close="true"
                :class="{ xiangQing: isActive }"
                width="calc(388/1920*100vw)"
        >
            <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
            <div class="contentAdd">
                <el-form :model="alarmInfo" ref="ruleForm" :rules="rules">
          <el-form-item>
            <span class="label_r">测流点名称:</span>
            <el-input
              v-if="alarmInfo.meterName"
              v-model="alarmInfo.meterName"
              class="xiangQing"
              readonly
            ></el-input>
            <el-input
              v-if="alarmInfo.name"
              v-model="alarmInfo.name"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item prop="nickName">
            <span class="label_r">流速:</span>
            <el-input
            v-if="alarmInfo.flowSpeed"
              v-model="alarmInfo.flowSpeed+'（m/s）'"
              class="xiangQing"
              readonly
            ></el-input>
             <el-input
            v-if="alarmInfo.speed"
              v-model="alarmInfo.speed+'（m/s）'"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">水位:</span>
            <el-input
              v-if="alarmInfo.waterLevel"
              v-model="alarmInfo.waterLevel+'（m）'"
              class="xiangQing"
              readonly
            ></el-input>
            <el-input
              v-if="alarmInfo.level"
              v-model="alarmInfo.level"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">经度:</span>
            <el-input
              v-model="alarmInfo.lon"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">纬度:</span>
            <el-input
              v-model="alarmInfo.lat"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">瞬时流量:</span>
            <el-input
              v-model="alarmInfo.insFlow+'（m³/h）'"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">累计流量:</span>
            <el-input
            v-if="alarmInfo.totalFlow"
              v-model="alarmInfo.totalFlow+'（m³）'"
              class="xiangQing"
              readonly
            ></el-input>
             <el-input
              v-if="alarmInfo.sumFlow"
              v-model="alarmInfo.sumFlow+'（m³）'"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span class="label_r">采集时间:</span>
            <el-input
             v-if="alarmInfo.dataTime"
              v-model="alarmInfo.dataTime"
              class="xiangQing"
              readonly
            ></el-input>
             <el-input
              v-if="alarmInfo.caijiTime"
              v-model="alarmInfo.caijiTime"
              class="xiangQing"
              readonly
            ></el-input>
          </el-form-item>
        </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
      </span>
        </el-dialog>
    </div>
</template>

<script>
import { RecordList, alarmLogImmediate,getFlowMeteList } from '@/api/index'

export default {
  name: 'realTime',
  data () {
    return {
      isActive: false,
      rules: {
        description: [
          { required: true, message: '请输入处理详情', trigger: 'change' }
        ]
      },
      loading: false,
      list: [],
      disposeListsLength: 0,
      noDisposeListsLength: 0,
      addShow: false,
      title: '报警处理',
      status: false, // 弹框类型,
      alarmInfo: {},
      typeList: [
        '水位报警',
        '电量报警',
        '设备报警',
        '门禁报警',
        '非法入侵报警'
      ]
    }
  },
  computed: {
    classOption () {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: this.list.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)

      }
    }
  },
  created () {
    this.getData()
  },
  mounted () {

  },
  destroyed () {

  },
  methods: {

    getData () {
      this.loading = true;
      getFlowMeteList().then(res=>{
        this.list=res.data.ditchFlowMeters.concat(res.data.flowMeters)
       // this.totals =this.noDisposeLists.length;
           this.loading = false;
      })
      // RecordList().then(res => {
      //   this.loading = false
      //   if (res.code) {
      //     // 处理的数据
      //     const disposeLists = res.data.disposeLists.length === 0 ? [] : res.data.disposeLists.map(item => { return { ...item, status: 0 } })
      //     this.disposeListsLength = res.data.disposeLists.length
      //     // 未处理的数据
      //     const noDisposeLists = res.data.noDisposeLists.length === 0 ? [] : res.data.noDisposeLists.map(item => { return { ...item, status: 1 } })
      //     this.noDisposeListsLength = res.data.noDisposeLists.length
      //     // 拼接数据到前端展示
      //     this.list = noDisposeLists.concat(disposeLists)

      //     this.$emit('titleNum', [this.noDisposeListsLength, this.disposeListsLength + this.noDisposeListsLength])
      //   }
      // })
    },
    clickPropsDetail(e) {
      let index = e.target.id;
      let item = JSON.parse(e.target.dataset.obj);
      this.handleProce(item);
    },
    // 立即处理
    handleProce (item) {
      // console.log(item)
      this.addShow = true
      this.title = '测流点实时信息'
      this.status = true
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
    },
    handleSave () {
      // 用户信息
      const { userId, userName } = JSON.parse(localStorage.getItem('userInfo'))
      // 报警信息
      const { description, id } = this.alarmInfo
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alarmLogImmediate({
            handlerId: userId,
            handlerName: userName,
            description,
            id
          })
            .then(res => {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.addShow = false
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: 'error'
              })
            })
            .finally(() => {
              this.getData()
            })
        }
      })
    },
    handleShow () {
      this.addShow = false
      this.alarmInfo = {}
    },
    closeBox () {
      this.$refs.ruleForm.resetFields()
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.seamless-warp {
    height: calc(210 / 1080 * 100vh);
    overflow: hidden;
}
.dispose {
    height: calc(245 / 1080 * 100vh);
    overflow: hidden;
    .seamless {
        overflow: hidden;
    }
}
.contentAdd {
    padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
    width: calc(244 / 1920 * 100vw);
}
::v-deep .el-textarea {
    height: calc(117 / 1080 * 100vh) !important;
    .el-textarea__inner {
        height: 100%;
    }
}
::v-deep .el-input__inner {
    border: none;
    border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
    border-radius: 0;
}
::v-deep .el-form-item__content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
}

::v-deep .el-dialog {
    background: rgba(6,108,151,0.6);
    border-radius: 16px;
    .el-dialog__header {
        background: rgba(6,108,151,0.6);
        border-radius: 16px 16px 0 0;
        color: #fff;
    }
    .el-dialog__footer {

        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(6,108,151,0.6);
        border-radius: 0 0 16px 16px;
        padding: calc(15 / 1080 * 100vh) 0;
        .el-button:nth-child(1):hover {
            background: #12B2D3;
            border: 1px #eaeaea solid;
            color: #fff;
        }
    }
    // 弹框主体
    .el-dialog__body {
        background: rgba(6,108,151,0.6);
        height: calc(395 / 1080 * 100vh);
        // 菜单选框
        .el-checkbox-group {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            label {
                width: 34% !important;
                height: calc(25 / 1080 * 100vh);
                margin-right: 0;

            }
        }

    }
    .el-dialog__headerbtn .el-dialog__close{
      color: #fff;
    }
    .el-input__inner {
        border: none;
        border-radius: 4px;
        background: rgba(0,8,46, 0.8);
        color: #F2FDFF;
    }
    .label_r {
        margin-left: 20px !important;
        text-align: left;
        color: #fff;

        width: calc(86 / 1920 * 100vw);
        // margin-bottom: 10px;
    }
}

::v-deep .xiangQing {
    .el-input__inner {
        background: none;
    }
    .el-textarea__inner {
        background: #fff;
        margin-top: calc(6 / 1080 * 100vh);
    }
}
::v-deep .el-dialog__header {
    border-bottom: 1px solid transparent;
    border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
    font-size: calc(20 / 1920 * 100vw);
    color: #333;
    height: calc(245 / 1080 * 100vh);
    text-align: center;
    line-height: calc(245 / 1080 * 100vh);
}
.dispose_five {
    height: calc(245 / 1080 * 100vh);
    overflow: hidden;
}

.reason {
    .el-textarea {
        height: calc(58 / 1080 * 100vh) !important;
    }
}
.el-form-item{
  margin-bottom: 0;
}
.Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height:calc(23 / 1920 * 100vw);
  }
</style>
