import { render, staticRenderFns } from "./realTime.vue?vue&type=template&id=cde007a4&scoped=true&"
import script from "./realTime.vue?vue&type=script&lang=js&"
export * from "./realTime.vue?vue&type=script&lang=js&"
import style0 from "./realTime.vue?vue&type=style&index=0&id=cde007a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde007a4",
  null
  
)

export default component.exports