<template>
    <div class="lineChart">
      <div class="TitlesR">
          <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange">
            <el-option
             v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.deviceAddress">
            </el-option>
          </el-select>
        </div>
        <div class="lineChart" id="lineChart"></div>
    </div>
</template>

<script>
import { YearsList,siteGetSiteAll } from '../../../../api/index'
export default {
  name: 'lineChart',
  data () {
    return {
      waterList:[],//下拉
      water:'',
      lastLevelList: [], // 后台返回的去年水位表
      nowLevelList: [] // 后台返回的今年水位表
    }
  },
  mounted () {
     siteGetSiteAll().then(res=>{
      this.waterList=res.data
      this.water=this.waterList[0].deviceAddress
       this.flow=this.waterList[0].deviceAddress
      this.getData(this.waterList[0].deviceAddress);
     // this.dataProcessings(this.waterList[0].deviceAddress)
    })
  },
  methods: {
    init (last, now, year, max, min) {
      const ImgIcon = require('@/assets/images/dot.png')
      const nowImgIcon = require('@/assets/images/nowDot.png')
      const lineChart = document.getElementById('lineChart')
      const myChart = this.$echarts.init(lineChart)
      const option = {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
            color: '#D1D1D1'
          }
        },
        xAxis: {
          name:"月",
           nameTextStyle:{
            color:"#fff",
            fontSize:10,
        },
          type: 'category',
          data: year,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#EDFEFF',
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#1D56C0',
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            if (params.length == 1) {
              return params[0].name + '月' + '<br/>' + params[0].marker + params[0].seriesName + ': ' + params[0].value
            } else {
              return params[0].name + '月' + '<br/>' + params[0].marker + params[0].seriesName + ': ' + params[0].value + '<br/>' + params[1].marker + params[1].seriesName + ': ' + params[1].value
            }
          }
        },
        yAxis: {
          name:"单位/cm",
           nameTextStyle:{
            color:"#fff",
            fontSize:10,
        },
          type: 'value',
          min: min,
          max: max,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#1D56C0'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#EDFEFF',
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        series: [
          {
            name: '去年',
            data: last,
            type: 'line',
            showSymbol: true, // 是否默认展示圆点
            symbol: `image://${ImgIcon}`, // 设定为实心点
            symbolSize: 16, // 设定实心点的大小
            itemStyle: {
              normal: {
                color: '#31EEFF',
                width: 8,
                lineStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{ // 1代表上面
                    offset: 0,
                    color: '#9955FF'
                  }, {
                    offset: 1,
                    color: '#28E0EE'
                  }]),
                  width: 4
                }
              }
            }
          },
          {
            name: '今年',
            data: now,
            type: 'line',
            showSymbol: true, // 是否默认展示圆点
            symbol: `image://${nowImgIcon}`, // 设定为实心点
            symbolSize: 16, // 设定实心点的大小
            itemStyle: {
              normal: {
                color: '#40F58D',
                width: 8,
                lineStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{ // 1代表上面
                    offset: 0,
                    color: '#FFA415'
                  }, {
                    offset: 1,
                    color: '#40F58D'
                  }]),
                  width: 4
                }
              }
            }
          }]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    getData (val) {
      YearsList(val).then(res => {
        // console.log(res)
        // 去年流量表
        this.lastLevelList = res.data.lastLevel
        // 今年流量表
        this.nowLevelList = res.data.nowLevel
        const lastNum = this.lastLevelList.map(item => item.level)
        const nowNum = this.nowLevelList.map(item => item.level)
        const arr = lastNum.concat(nowNum)
        const max = Math.max(...arr)
        const min = Math.min(...arr)
        this.init(
          this.lastLevelList.map(item => item.level),
          this.nowLevelList.map(item => item.level),
          res.data.nowLevel.map(item => item.month)),
        max, min
      })
    },
    waterChange(val){
      this.getData(val)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.TitlesR{
  position:absolute;
  top: 20px;
  right: 15px;
  z-index: 999;
}
</style>
