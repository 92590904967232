<template>
  <div class="Swipers Card-b-c">
    <div class="TitlesR">
       <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange">
            <el-option
             v-for="(item,index) in stationBzList"
              :key="item.value"
              :label="index+1+' '+item.label"
              :value="item.value">
            </el-option>
          </el-select>
      </div>
        <div class="boxrow">
          <el-row class="boxrowone">
            <el-col :span="8" style="margin-bottom:18px" v-for="(item,index) in radioList" :key="index"><div >
              <el-radio-group v-model="radio" @change="changeRadio" >
                    <el-radio  :label="index" :key="index">{{item.staName||'设备'+item.staId}}</el-radio>
                  </el-radio-group>
            </div>
            </el-col>
          </el-row>
          <el-row :gutter="24"  class="rowbeng">
            <el-col :span="9"><div >
              <span class="rowOne">瞬时流量:</span>
              <span class="rowTwo">{{topInfo.instantaneousDelivery || '0'}}(m³/h)</span>
            </div>
            </el-col>
            <el-col :span="7" ><div>
                <span class="rowOne">A相电压:</span>
              <span class="rowTwo">{{topInfo.aPhaseVoltage || '0'}}V</span>
              </div></el-col>
              <el-col :span="7" ><div>
                <span class="rowOne">A相电流:</span>
              <span class="rowTwo">{{topInfo.aPhaseCurrent || '0'}}A</span>
              </div></el-col>
          </el-row>
          <el-row :gutter="24"  class="rowbeng">
            <el-col :span="9"><div >
              <span class="rowOne">总有功电能:</span>
              <span class="rowTwo">{{topInfo.totalPower || '0'}}(kW.h)</span>
            </div>
            </el-col>
            <el-col :span="7"><div >
              <span class="rowOne">B相电压:</span>
              <span class="rowTwo">{{topInfo.bPhaseVoltage || '0'}}V</span>
            </div>
            </el-col>
            <el-col :span="7"><div >
              <span class="rowOne">B相电流:</span>
              <span class="rowTwo">{{topInfo.bPhaseCurrent || '0'}}A</span>
            </div>
            </el-col>
          </el-row>
          <el-row :gutter="24"  class="rowbeng">
            <el-col :span="9"><div >
              <span class="rowOne">累计流量:</span>
              <span class="rowTwo">{{topInfo.cumulativeDischarge || '0'}}(m³)</span>
            </div>
            </el-col>
            <el-col :span="7" ><div>
                <span class="rowOne">C相电压:</span>
              <span class="rowTwo">{{topInfo.cPhaseVoltage || '0'}}V</span>
              </div></el-col>
              <el-col :span="7" ><div>
                <span class="rowOne">C相电流:</span>
              <span class="rowTwo">{{topInfo.cPhaseCurrent || '0'}}A</span>
              </div></el-col>
          </el-row>
        </div>
  </div>
  <!-- <div class="Swipers Card-b-c" v-loading="loading" element-loading-text="数据量过大，加载中...">
    <Titles Titles="泵站实时信息" show="true" :total="total" />
    <el-carousel trigger="click">
      <el-carousel-item v-for="(item, i) in MonthList" :key="i">
        <img style="width: 100%; height: 100%;" :src="item.alarmPicUrl" alt=""/>
        <viewer style="height: 100%;"><img style="width: 100%; height: 100%;" :src="item.alarmPicUrl" alt=""/></viewer>
      </el-carousel-item>
    </el-carousel>
  </div> -->
</template>

<script>
import { MonthList } from "../../../../api/index";
import Titles from "../../../../components/Titles/index";
export default {
  data() {
    return {
      waterList:[],
      water:'',
      radio: 0,
      topInfo:{},
      radioList: [],
      radioVal:{},
      stationBzList:[],//泵站列表下拉
      MonthList: [], //本月抓拍列表,
      total: 0,
      show: false,
      loading: false
    };
  },
  mounted() {
    this.loading = true;
    MonthList().then(res => {
      this.MonthList = res.data.slice(0, 5);
      this.total = res.data.length;
      if (this.MonthList.length == "0") {
        this.show = true;
      }
      this.loading = false;
    });
    this.showDown()
  },
  methods:{
    // 下拉列表
    showDown(){
       this.$get('/pumpStation/getPumpStations').then(res => {
        // console.log(res)
        if (res.code == 200) {
          this.stationBzList = res.data.map(item => {
            return {
              value: item.id,
              label: item.staName
            }
          })
          // console.log( this.stationBzList);
          this.showDownid(this.stationBzList[0].value)
          this.water=this.stationBzList[0].value
        } else {
          this.stationBzList = []
        }
      })
    },
    changeRadio (val) {
    this.topInfo = this.radioList[val]
    },
    showDownid(val){
      this.$get(`/pumpStationItem/getPumpStationItemIdByPid?pid=${val}`).then(res => {
        // console.log(res)
        if (res.code == 200) {
           if (res.data.length != 0) {
            this.radioList = res.data
            this.topInfo = res.data[this.radio]
          } else {
            this.radioList = []
            this.topInfo = { type: 0 }
          }
        }
      })
    },
    waterChange(val){
      this.radio=0
      this.showDownid(val)
    }
  },
  components: {
    Titles
  }
};
</script>

<style lang="scss" scoped>
.Swipers {
  width: calc(650 / 1920 * 100vw);
  height: calc(310 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);
  position: relative;
  background: none;
  background-image: url("~@/assets/images/rectangle.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
  // 轮播图的圆角
  .el-carousel--horizontal {
    // border-radius: 0 0 8px 8px;
     margin: calc(12/1920*100vw);
    img {
      width: 100%;
      height: 92%;
    }
  }
}
::v-deep .el-carousel__container {
  height: calc(235 / 1080 * 100vh) !important;
}

.boxrow{
  padding:25px 0px 0px 50px;
  color: #fff;
  font-weight: 700;

  .boxrowone{
    height: 50px;
  }
  .rowbeng{
    padding: 30px 0px 10px 0px;
    .rowOne{
      color: #FFFFFF;
      font-weight: 400;
      padding-right: 10px;
    }
    .rowOTwo{

      color: #FFFFFF;

    }
  }
}
.TitlesR{
  position:absolute;
  top: -55px;
  right: 8px;
  z-index: 999;
}
.el-radio{
  color:#fff;
}
</style>
